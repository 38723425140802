<template>
  <div class="form_warp">
    <div class="form-box" id="formBox" v-loading="loading">
      <el-form :model="contractForm" :rules="rules" ref="contractFormRef" :label-position="labelPosition" label-width="170px" class="demo-contractForm">
        <el-form-item label="(乙方)姓名" prop="name">
          <el-input v-model="contractForm.name"></el-input>
        </el-form-item>
        <el-form-item label="(乙方)性别" prop="sex">
          <el-radio-group v-model="contractForm.sex">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="(乙方)联系方式" prop="phone">
          <el-input v-model="contractForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="(乙方)身份证号" prop="IDNumber">
          <el-input v-model="contractForm.IDNumber"></el-input>
        </el-form-item>
        <el-form-item label="劳务合同期限开始日期" prop="contractDeadlineStartDate">
          <el-date-picker
            v-model="contractForm.contractDeadlineStartDate"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="劳务合同期限结束日期" prop="contractDeadlineEndDate">
          <el-date-picker
            v-model="contractForm.contractDeadlineEndDate"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="试用期开始日期" prop="probationStartDate">
          <el-date-picker
            v-model="contractForm.probationStartDate"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="试用期结束日期" prop="probationEndDate">
          <el-date-picker
            v-model="contractForm.probationEndDate"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">立即创建</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getContractTemplateDetail } from '@/api/api'

export default {
  name: 'FormView',
  data() {
    return {
      labelPosition: 'right',
      contractForm: {
        type: 0,
        template_file: '', // 模板图片地址
        companyName: '',  // 甲方单位名称
        companyAddress: '',  // 甲方单位地址
        legalPerson: '',  // 甲方法人
        name: '',  // 乙方姓名
        sex: '',  // 乙方性别
        phone: '',  // 乙方联系方式
        IDNumber: '',  // 乙方身份证号
        contractDeadlineStartDate: '',  // 合同期限开始日期
        contractDeadlineEndDate: '',  // 合同期限结束日期
        probationStartDate: '',  // 试用期开始日期
        probationEndDate: '',  // 试用期结束日期
        workplace: '',  // 工作地
        payday: '',  // 发薪日
        hourlyWage: '',  // 时薪
        equalPay: false,  // 是否是同工同酬
      },
      rules: {
        name: [
          { required: true, message: '请输入乙方姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择乙方性别', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入乙方联系方式', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        IDNumber: [
          { required: true, message: '请输入乙方身份证号', trigger: 'blur' },
          { validator: this.IDNumberValidator, trigger: 'blur' }
        ],
        contractDeadlineStartDate: [
          { required: true, message: '请选择日期', trigger: 'change' },
          { validator: this.validateContractDeadline, trigger: 'change' }
        ],
        contractDeadlineEndDate: [
          { required: true, message: '请选择日期', trigger: 'change' },
          { validator: this.validateContractDeadline, trigger: 'change' }
        ],
        probationStartDate: [
          { required: true, message: '请选择日期', trigger: 'change' },
          { validator: this.validateProbation, trigger: 'change' }
        ],
        probationEndDate: [
          { required: true, message: '请选择日期', trigger: 'change' },
          { validator: this.validateProbation, trigger: 'change' }
        ],
      },
      contractTemplateId: '', // 合同模板地址
      loading: false,
      haveContractInfo: false, // 是否查到了合同信息
      isLegal: false, // 链接是否合法
      isActive: false, // 链接是否未过期
      isUsed: false // 链接是否已使用完可用次数
    }
  },
  mounted() {
    window.addEventListener('resize', this.listenResize);

    if (document.body.clientWidth < 600) {
      this.labelPosition = 'top'
    } else {
      this.labelPosition = 'right'
    }

    const query = this.$route.query
    if (query && query.contractTemplateId !== undefined && query.contractTemplateId !== null && query.contractTemplateId !== '') {
      this.contractTemplateId = query.contractTemplateId
      this.isLegal = true
      this.handleLoadContractTemplateDetail()
    } else {
      this.isLegal = false
      this.$message.warning('无效链接')
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize",this.listenResize);
  },
  methods: {
    validateContractDeadline(rule, value, callback) {
      if (!this.contractForm.contractDeadlineStartDate || !this.contractForm.contractDeadlineEndDate) {
        callback();
        return
      }
      const start = new Date(this.contractForm.contractDeadlineStartDate)
      const end = new Date(this.contractForm.contractDeadlineEndDate)
      if (start.getTime() > end.getTime()) {
        callback(new Error('开始日期不能大于结束日期'));
      } else {
        callback();
      }
    },
    validateProbation(rule, value, callback) {
      if (!this.contractForm.probationStartDate || !this.contractForm.probationEndDate) {
        callback();
        return
      }
      const start = new Date(this.contractForm.probationStartDate)
      const end = new Date(this.contractForm.probationEndDate)
      if (start.getTime() > end.getTime()) {
        callback(new Error('开始日期不能大于结束日期'));
      } else {
        callback();
      }
    },
    listenResize() {
      if (document.body.clientWidth < 600) {
        this.labelPosition = 'top'
      } else {
        this.labelPosition = 'right'
      }
    },
    IDNumberValidator (rule, value, callback) {
      let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      let _IDre15 =  /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
      if (!value) {
        return callback(new Error('证件号码不能为空'))
      } else if (_IDRe18.test(value) || _IDre15.test(value)) {
        callback()
      } else {
        return callback(new Error('证件号码不正确'))
      }
    },
    hourlyWageValidator (rule, value, callback) {
      if(!this.contractForm.equalPay && !value) {
        return callback(new Error('时薪不能为空'))
      } else {
        callback()
      }
    },
    handleLoadContractTemplateDetail() {
      this.loading = true
      getContractTemplateDetail({
        contractTemplateId: this.contractTemplateId
      }).then((res) => {
        this.loading = false
        this.haveContractInfo = true
        this.contractForm.type = res.data.type
        this.contractForm.template_file = res.data.template_file
        this.contractForm.companyName = res.data.company_name
        this.contractForm.companyAddress = res.data.company_address
        this.contractForm.legalPerson = res.data.juridical_person
        this.contractForm.workplace = res.data.work_address
        this.contractForm.payday = res.data.pay_date
        this.contractForm.hourlyWage = res.data.labour_cost
        this.contractForm.equalPay = res.data.salary_type === 2

        if (res.data.template_link_used_count >= res.data.template_link_used_limit) {
          this.isUsed = true
          this.$message.warning('该链接可用次数已达上限')
        } else {
          this.isUsed = false
        }

        if (res.data.template_link_create_time) {
          const timestamp = (new Date(res.data.template_link_create_time)).getTime()
          const currTime = Date.now()
          this.isActive = currTime - timestamp > 0 && currTime - timestamp < 1000 * 60 * 60 * 24
          if (!this.isActive) {
            this.$message.warning('该链接已过期')
          }
        } else {
          this.isActive = false
          this.$message.warning('该链接已过期')
        }

      }).catch((err) => {
        this.loading = false
        this.haveContractInfo = false
      })
    },
    submitForm() {
      if (!this.isLegal) {
        this.$message('无效链接')
        return
      }
      if (!this.haveContractInfo) {
        this.$message('未查到合同信息')
        return
      }
      if (this.isUsed) {
        this.$message.warning('该链接可用次数已达上限')
        return
      }
      if (!this.isActive) {
        this.$message.warning('该链接已过期')
        return
      }
      this.$refs['contractFormRef'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$router.push({
          path: '/home',
          query: {
            actionType: 'create',
            contractTemplateId: this.contractTemplateId,
            ...this.contractForm,
          }
        })
      })
    },
    resetForm() {
      this.$refs['contractFormRef'].resetFields()
    },
    /**
     * 将文字转为图片
     * */
    convertTextToPicture(text) {
      let canvas = document.createElement('canvas')
      canvas.height = 24
      canvas.width = 24
      canvas.style.cssText = `width: 24px; height: 24px; background-color: #808080;`
      let ctx = canvas.getContext('2d')
      ctx.font = '24px serif'
      ctx.textBaseline = 'bottom'
      ctx.fillText(text, 0, 24)
      return this.convertCanvasToImage(canvas)
    },
    convertCanvasToImage(canvas) {
      const image = new Image()
      image.src = canvas.toDataURL('image/png')
      return image
    },
  }
}
</script>

<style scoped lang="scss">
.form_warp {
  .form-box {
    padding: 0 20px;
    max-width: 600px;
    margin: 60px auto;
  }
}
</style>
