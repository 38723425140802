import axios from "@/utils/request"

/**
 * @desc 获取合同模板详情
 */
export function getContractTemplateDetail(data) {
  return axios.get("/public/contractTemplateDetail", {
    params: data
  })
}

/**
 * @desc 获取合同详情
 */
export function getContractDetail(data) {
  return axios.get("/public/contractDetail", {
    params: data
  })
}

/**
 * @desc 签署合同
 */
export function postContractFileUpload(data, params) {
  return axios({
    url: "/public/contractFileUpload?contractId=" + params.contractId + '&name=' + params.name,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

/**
 * @desc 新增并签署合同
 */
export function postAddAndSignContract(data, params) {
  return axios({
    url: "/public/addContractAndFileUpload?contractTemplateId=" + params.contractTemplateId + '&name=' + params.name,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
