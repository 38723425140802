<template>
  <div class="home_warp">
    <div id="canvas-box">
    </div>
    <div class="button-box">
      <el-button v-if="actionType === 'create'" type="primary" :loading="subLoading" @click="handleSign" style="height: 110px; width: 200px; font-size: 60px;">签字</el-button>
      <el-button v-if="actionType === 'signed'" type="primary" :loading="subLoading" @click="handleSubmit" style="height: 110px; width: 200px; font-size: 60px;">提交</el-button>
    </div>
  </div>
</template>

<script>
import ImageBg from '@/assets/contract_template.jpg'
import { postAddAndSignContract } from '@/api/api'

export default {
  name: 'HomeView',
  data () {
    return {
      actionType: 'create',
      canvasWidth: 0,
      canvasHeight: 0,
      contractTemplateId: '', // 合同模板id
      name: '', // 乙方姓名
      canvas: null,
      signedImageUrl: '',
      backgroundImageUrl: '',
      subLoading: false,
      isHorizontal: false,
    }
  },
  mounted() {
    const query = this.$route.query
    if (query) {
      this.actionType = query.actionType
      if (query.actionType === 'create') {
        let contractForm = {...query}
        this.name = query.name
        this.contractTemplateId = query.contractTemplateId
        this.init(contractForm)
      }
      // if (query.actionType === 'sign') {
      //   this.backgroundImageUrl = query.url
      //   this.initSign(this.backgroundImageUrl)
      // }
      if (query.actionType === 'signed') {
        this.contractTemplateId = query.contractTemplateId
        this.name = query.name
        this.isHorizontal = query.direction === 'horizontal'? true : false
        this.initSigned()
      }
    }
  },
  methods: {
    init(contractForm) {
      const canvasBox = document.getElementById('canvas-box')
      const imageBg = new Image()
      if (parseInt(contractForm.type) === 1) {
        imageBg.src = contractForm.template_file.replace('signadmin', 'sign')
      } else {
        imageBg.src = ImageBg
      }
      imageBg.onload = () => {
        this.canvas = document.createElement('canvas')
        this.canvasWidth = imageBg.width
        this.canvasHeight = imageBg.height
        this.canvas.width = this.canvasWidth
        this.canvas.height = this.canvasHeight
        this.canvas.style.cssText = `width: ${this.canvasWidth}px; height: ${this.canvasHeight}px;`
        canvasBox.appendChild(this.canvas)
        canvasBox.style.cssText = `min-width: ${this.canvasWidth}px;`
        const ctx = this.canvas.getContext('2d')
        ctx.drawImage(imageBg, 0, 0, this.canvasWidth, this.canvasHeight)
        ctx.font = '22px serif'
        ctx.textBaseline = 'bottom'
        
        if (parseInt(contractForm.type) === 1) {
          ctx.fillText(contractForm.name, this.canvasWidth * 0.3, this.canvasHeight * 0.221)
          ctx.fillText(contractForm.sex, this.canvasWidth * 0.64, this.canvasHeight * 0.221)
          ctx.fillText(contractForm.phone, this.canvasWidth * 0.3, this.canvasHeight * 0.245)
          ctx.fillText(contractForm.IDNumber, this.canvasWidth * 0.64, this.canvasHeight * 0.245)
          let contractDeadlineStartArr = contractForm.contractDeadlineStartDate.split('-')
          let contractDeadlineEndArr = contractForm.contractDeadlineEndDate.split('-')
          ctx.fillText(contractDeadlineStartArr[0], this.canvasWidth * 0.358, this.canvasHeight * 0.321)
          ctx.fillText(Number(contractDeadlineStartArr[1]), this.canvasWidth * 0.416, this.canvasHeight * 0.321)
          ctx.fillText(Number(contractDeadlineStartArr[2]), this.canvasWidth * 0.458, this.canvasHeight * 0.321)
          ctx.fillText(contractDeadlineEndArr[0], this.canvasWidth * 0.534, this.canvasHeight * 0.321)
          ctx.fillText(Number(contractDeadlineEndArr[1]), this.canvasWidth * 0.591, this.canvasHeight * 0.321)
          ctx.fillText(Number(contractDeadlineEndArr[2]), this.canvasWidth * 0.632, this.canvasHeight * 0.321)
          let probationStartArr = contractForm.probationStartDate.split('-')
          let probationEndArr = contractForm.probationEndDate.split('-')
          ctx.fillText(probationStartArr[0], this.canvasWidth * 0.15, this.canvasHeight * 0.34)
          ctx.fillText(Number(probationStartArr[1]), this.canvasWidth * 0.207, this.canvasHeight * 0.34)
          ctx.fillText(Number(probationStartArr[2]), this.canvasWidth * 0.248, this.canvasHeight * 0.34)
          ctx.fillText(probationEndArr[0], this.canvasWidth * 0.326, this.canvasHeight * 0.34)
          ctx.fillText(Number(probationEndArr[1]), this.canvasWidth * 0.383, this.canvasHeight * 0.34)
          ctx.fillText(Number(probationEndArr[2]), this.canvasWidth * 0.424, this.canvasHeight * 0.34)

          let date = new Date()
          let dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
          // ctx.fillText(dateString, this.canvasWidth * 0.23, this.canvasHeight * 0.928)
          ctx.fillText(dateString, this.canvasWidth * 0.57, this.canvasHeight * 0.943)
        } else {
          ctx.fillText(contractForm.name, this.canvasWidth * 0.3, this.canvasHeight * 0.215)
          ctx.fillText(contractForm.sex, this.canvasWidth * 0.64, this.canvasHeight * 0.215)
          ctx.fillText(contractForm.phone, this.canvasWidth * 0.3, this.canvasHeight * 0.241)
          ctx.fillText(contractForm.IDNumber, this.canvasWidth * 0.64, this.canvasHeight * 0.241)
          let contractDeadlineStartArr = contractForm.contractDeadlineStartDate.split('-')
          let contractDeadlineEndArr = contractForm.contractDeadlineEndDate.split('-')
          ctx.fillText(contractDeadlineStartArr[0], this.canvasWidth * 0.358, this.canvasHeight * 0.315)
          ctx.fillText(Number(contractDeadlineStartArr[1]), this.canvasWidth * 0.416, this.canvasHeight * 0.315)
          ctx.fillText(Number(contractDeadlineStartArr[2]), this.canvasWidth * 0.458, this.canvasHeight * 0.315)
          ctx.fillText(contractDeadlineEndArr[0], this.canvasWidth * 0.534, this.canvasHeight * 0.315)
          ctx.fillText(Number(contractDeadlineEndArr[1]), this.canvasWidth * 0.591, this.canvasHeight * 0.315)
          ctx.fillText(Number(contractDeadlineEndArr[2]), this.canvasWidth * 0.632, this.canvasHeight * 0.315)
          let probationStartArr = contractForm.probationStartDate.split('-')
          let probationEndArr = contractForm.probationEndDate.split('-')
          ctx.fillText(probationStartArr[0], this.canvasWidth * 0.15, this.canvasHeight * 0.334)
          ctx.fillText(Number(probationStartArr[1]), this.canvasWidth * 0.207, this.canvasHeight * 0.334)
          ctx.fillText(Number(probationStartArr[2]), this.canvasWidth * 0.248, this.canvasHeight * 0.334)
          ctx.fillText(probationEndArr[0], this.canvasWidth * 0.326, this.canvasHeight * 0.334)
          ctx.fillText(Number(probationEndArr[1]), this.canvasWidth * 0.383, this.canvasHeight * 0.334)
          ctx.fillText(Number(probationEndArr[2]), this.canvasWidth * 0.424, this.canvasHeight * 0.334)
          
          ctx.fillText(contractForm.companyName, this.canvasWidth * 0.33, this.canvasHeight * 0.108)
          ctx.fillText(contractForm.companyAddress, this.canvasWidth * 0.33, this.canvasHeight * 0.134)
          ctx.fillText(contractForm.legalPerson, this.canvasWidth * 0.33, this.canvasHeight * 0.165)
          ctx.fillText(contractForm.workplace, this.canvasWidth * 0.28, this.canvasHeight * 0.388)
          ctx.fillText(contractForm.payday, this.canvasWidth * 0.592, this.canvasHeight * 0.537)
          if (contractForm.equalPay === 'true') {
            ctx.fillText('√', this.canvasWidth * 0.515, this.canvasHeight * 0.557)
          } else {
            ctx.fillText('√', this.canvasWidth * 0.413, this.canvasHeight * 0.557)
            ctx.fillText(contractForm.hourlyWage, this.canvasWidth * 0.433, this.canvasHeight * 0.557)
          }

          let date = new Date()
          let dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
          // ctx.fillText(dateString, this.canvasWidth * 0.23, this.canvasHeight * 0.928)
          ctx.fillText(dateString, this.canvasWidth * 0.57, this.canvasHeight * 0.928)
        }
      }
    },
    // initSign(imgUrl) {
    //   const canvasBox = document.getElementById('canvas-box')
    //   const imageBg = new Image()
    //   imageBg.src = imgUrl
    //   imageBg.onload = () => {
    //     this.canvas = document.createElement('canvas')
    //     this.canvasWidth = imageBg.width
    //     this.canvasHeight = imageBg.height
    //     this.canvas.width = this.canvasWidth
    //     this.canvas.height = this.canvasHeight
    //     this.canvas.style.cssText = `width: ${this.canvasWidth}px; height: ${this.canvasHeight}px;`
    //     canvasBox.appendChild(this.canvas)
    //     canvasBox.style.cssText = `min-width: ${this.canvasWidth}px;`
    //     const ctx = this.canvas.getContext('2d')
    //     ctx.drawImage(imageBg, 0, 0, this.canvasWidth, this.canvasHeight)
    //   }
    // },
    initSigned() {
      this.backgroundImageUrl = window.localStorage.getItem('backgroundImageUrl')
      this.signedImageUrl = window.localStorage.getItem('signedImageUrl')
      let i = 0
      const imageBg = new Image()
      imageBg.src = this.backgroundImageUrl
      const imageName = new Image()
      imageName.src = this.signedImageUrl
      imageBg.onload = () => {
        i++
        this.initNameImage(i, imageBg, imageName)
      }
      imageName.onload = () => {
        i++
        this.initNameImage(i, imageBg, imageName)
      }
    },
    initNameImage(i, imageBg, imageName) {
      if (i <= 1) return;
      const canvasBox = document.getElementById('canvas-box')
      this.canvas = document.createElement('canvas')
      this.canvasWidth = imageBg.width
      this.canvasHeight = imageBg.height
      this.canvas.width = this.canvasWidth
      this.canvas.height = this.canvasHeight
      this.canvas.style.cssText = `width: ${this.canvasWidth}px; height: ${this.canvasHeight}px;`
      canvasBox.appendChild(this.canvas)
      canvasBox.style.cssText = `min-width: ${this.canvasWidth}px;`
      const ctx = this.canvas.getContext('2d')
      ctx.drawImage(imageBg, 0, 0, this.canvasWidth, this.canvasHeight)

      if (this.isHorizontal) {
        const ratio = (this.canvasWidth / 5) / imageName.width
        //                                          左右                       上下
        ctx.drawImage(imageName, this.canvasWidth * 0.58, this.canvasHeight * 0.87, imageName.width * ratio, imageName.height * ratio)
      } else {
        const ratio = (this.canvasWidth / 10) / imageName.width
        ctx.translate(this.canvasWidth / 2, this.canvasHeight / 2); //设置画布上的(0,0)位置，也就是旋转的中心点
        ctx.rotate(90 * Math.PI / 180);
        //                                         左右                        上下
        ctx.drawImage(imageName, this.canvasWidth * 0.52, this.canvasHeight * -0.18, imageName.width * ratio, imageName.height * ratio)
      }
    },
    dataURLtoBlob: function(dataUrl, fileName) {
      let arr = dataUrl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bStr = atob(arr[1]),
          n = bStr.length,
          u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bStr.charCodeAt(n)
      }
      return new File([u8arr],fileName, { type: mime })
    },
    handleSign() {
      this.subLoading = true
      const image = this.convertCanvasToImage(this.canvas)
      this.backgroundImageUrl = image.src
      window.localStorage.setItem('backgroundImageUrl', this.backgroundImageUrl)
      this.subLoading = false
      this.$router.push({
        path: '/sign',
        query: {
          contractTemplateId: this.contractTemplateId,
          name: this.name,
        }
      })
    },
    convertCanvasToImage(canvas) {
      const image = new Image()
      image.src = canvas.toDataURL('image/png')
      return image
    },
    handleSubmit() {
      const isSigned = sessionStorage.getItem('isSigned')
      if (isSigned === 'yes') {
        this.$message.warning('已签署成功，不用再次签署')
        return
      }
      this.subLoading = true
      const image = this.convertCanvasToImage(this.canvas)
      const src = image.src
      const filename = '合同.png'
      let blob = this.dataURLtoBlob(src, filename)
      let formData = new FormData()
      formData.append('file', blob)
      postAddAndSignContract(formData, {
        contractTemplateId: parseInt(this.contractTemplateId),
        name: this.name,
      }).then((res) => {
        this.subLoading = false
        this.$message.success('签署成功')
        sessionStorage.setItem('isSigned', 'yes')
      }).catch((err) => {
        console.error(err);
        this.subLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home_warp {
  background-color: rgba(0, 0 , 0, 0.5);
  width: 100%;
  overflow: auto;

  #canvas-box {
    text-align: center;
    font-size: 0;
  }

  .button-box {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
}
</style>
