import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignView from '../views/SignView.vue'
import FormView from '../views/FormView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/form'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sign',
    name: 'sign',
    component: SignView
  },
  {
    path: '/form',
    name: 'form',
    component: FormView
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
