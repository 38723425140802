import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css';
import {
  Button,
  Form,
  FormItem,
  Input,
  DatePicker,
  RadioGroup,
  Radio,
  Message,
  Select,
  Option,
  Checkbox,
  Row,
  Col,
  Loading ,
} from 'element-ui';

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(DatePicker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Row)
Vue.use(Col)
Vue.use(Loading)
Vue.prototype.$message = function(msg) {
  return Message({
    message: msg,
    customClass: "message-box"
  })
}
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    customClass: "message-box"
  })
}
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    customClass: "message-box"
  })
}
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    customClass: "message-box"
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
